

.loader {
  border: 5px solid #f3f3f3;
  /* Light grey */
  border-top: 5px solid #58b304;
  /* Blue */
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  animation: spin 2s linear infinite;
  margin-top: 150px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

.loader-2 {
  border: 3px solid #f3f3f3;
  /* Light grey */
  border-top: 3px solid #58b304;
  /* Blue */
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: spin 2s linear infinite;
}

.map-container {
  height: 20rem;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');


.flexx{
  /* display: flex !important; */
  text-align: center;
  width: 200%;

}
.align{
  text-align: center !important;
}

.pagination-button{
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 0.2rem;
}

.pagination-button a{
  display: block;
  padding: 0.5rem 1rem;

}
.react-datepicker__header.react-datepicker__header--custom{
  background: white !important;
}

.react-datepicker__day--selected{
  background-color: #58b304 !important;
  border-radius: 100% !important;
  color: white !important;
}

.react-datepicker__day--keyboard-selected{
  background-color: white !important;
}

.react-datepicker__navigation--years {
  background: none !important;
  line-height: 1.7rem !important;
  text-align: center !important;
  cursor: pointer !important;
  padding: 0 !important;
  border: 0.45rem solid transparent !important;
  z-index: 1 !important;
  height: 10px !important;
  width: 10px !important;
  text-indent: -999em !important;
  overflow: hidden !important;
  position: relative !important;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  top: -4px !important;
  border-bottom-color: black !important;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  top: 4px !important;
  border-top-color: black !important;
}

.react-datepicker__year-option:hover{
  background: #e5e7eb !important;
}

.react-datepicker__navigation-icon--previous::before{
  border-color: black !important;
}

.react-datepicker__navigation-icon--next::before{
  border-color: black !important;
}

.react-datepicker__year-read-view--down-arrow{
  border-color: black !important;
}